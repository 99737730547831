const { Fetch } = require("../Fetch");
const { Render } = require("./Render");
const { Incept } = require("./Incept");
const { Rule } = require("./Rule");

let currentPage;

async function getAndRender({ url, target, operation = "inner" }) {
  const content = await Fetch({ url });
  console.log("getAndRender", { url, target, operation, content });
  return Render({ content, target, operation });
}

async function Page({ App }) {
  const { origin, pathname } = window.location;
  const _id = origin + pathname; //parts.slice(0, 4).join("/");

  const Pages = App.DB("pages");
  if (_id === currentPage) return;

  currentPage = _id;

  const page = await Pages?.findOne({ _id });

  if (page?.client) {
    const { script } = page.client;
    try {
      eval(script);
    } catch (error) {
      console.error("Error in page script", error);
    }
  }

  // await getPage({ App, Pages, _id, target, page: true });
}

async function getPage({ App, _id, target, page = false }) {
  const Pages = App.DB("pages");
  if (_id === currentPage) return;
  if (page) currentPage = _id;
  console.log({ _id });
  const tool = await Pages?.findOne({ _id });
  console.log("getPage", { _id, tool, page });

  if (tool?.client) {
    const { script } = tool.client;
    eval((() => script)());
  }

  if (!tool && page) {
    return Component({ App, name: "Chat", target: "#AI" });
  }

  const content = tool?.client?.["index.html"];

  if (content) Render({ content, target });
}

async function Component({ App, name, _id, target, operation = "inner" }) {
  console.log("Component", { name, _id, target, operation });
  if (_id) {
    await getPage({ App, _id, target });
    return;
  }
  url = `/app/components/${name}/index.html`;
  return getAndRender({ url, target, operation });
}

module.exports = { Rule, Page, Component, Render, Incept };
