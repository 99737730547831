const { Click } = require("./Events/Click");
const { Submit } = require("./Events/Submit");

const track = {
  a: Click,
  button: Click,
  form: Submit,
  img: Click,
};

const tags = Object.keys(track);

const Incept = (target) => {
  if (target?.getAttribute("data-incepted")) return true;

  function incepted(target) {
    console.log("incepted", target);

    if (target?.getAttribute("data-incepted")) return true;
    target.setAttribute("data-incepted", "true");

    return false;
  }

  incepted(target);

  tags.forEach((tag) => {
    console.log("tag", tag);
    const selector = `${tag}:not([data-incepted]`;

    target.querySelectorAll(selector).forEach((element) => {
      if (incepted(element)) return;
      track[tag](element);
    });
  });

  setTimeout(Incept, 500, target);

  return;
};

module.exports = { Incept };
