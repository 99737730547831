require("../Loader");
const { Incept } = require("../../lib/Incept/index.js");

class AiStoreBuilder extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" }); // Attach a shadow root to the element.
    this.state;
    this.action = "/e-com.express/shopify_com/sign-up";

    const in_progress = `
                <ai-loader></ai-loader>
                <p>I'm creating your store. This will take a couple minutes...</p>
                <p>In the meantime, watch this:</p>
                <div class="ai-video"><iframe frameborder="0" allowfullscreen="true" src="https://player.vimeo.com/video/905869041?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"></iframe></div>
            `;

    this.states = {
      default: `
                <p>My AI will build your store while I teach you how to make money with it.</p>
                <p>What is your email?</p>
                <form id="AI" action="${this.action}">
                    <input required="true" name="email" type="email" value="" autofocus placeholder="Enter your email to get started">
                    <button>BUILD FOR ME</button>
                </form>
            `,
      in_progress,
      done: `
                <p>Your Shopify account is ready!</p>
                <a class="button button-cta" href="https://app.e-com.express/subscribe/1720135083344x152847300412709760">Click To Continue</a>
            `,
      failed: `
                <p>😥 Sorry, I couldn't create you Shopify account.</p>
                <p>But with your help I can continue creating your store.</p>
                <a class="button button-cta" target="_blank" href="https://app.e-com.express/subscribe/1720135083344x152847300412709760">Click To Continue</a>
            `,
      to_do: in_progress,
      received: in_progress,
    };
  }

  setState(state = "default") {
    console.log("setState", state);
    if (state === this.state) return;

    this.state = state;

    const content = this.states[this.state];

    const element = this.shadowRoot.querySelector(
      ".ai-store-builder .ai-container"
    );
    element.innerHTML = content;

    Incept(element);

    const form = this.shadowRoot.querySelector("form");

    if (this.state !== "default") {
      this.shadowRoot
        .querySelector(".ai-store-builder")
        .classList.add("ai-fullscreen");
    }

    if (!form) return;

    this.shadowRoot.querySelector("input[type='email']").focus();

    form.addEventListener("submit", (event) => {
      setTimeout(() => {
        this.setState("in_progress");
        this.getJob({ force: true });
      }, 1000);
    });
  }

  async getJob({ force = false } = {}) {
    console.log("getJob", window.AI);
    const jobs = await window.AI()?.App?.DB("jobs");
    const query = {
      page_id: window.location.origin + window.location.pathname,
      user_id: window.AI()?.App?.currentUser.id,
      action: this.action,
    };
    console.log("query", query);
    const options = { sort: { created_at: -1 } };
    const job = await jobs.findOne(query, options);

    if (job) this.setState(job.status);

    if (!force && job?.status !== "in_progress") return;

    let changeStream = await jobs.watch({
      filter: { "fullDocument._id": job?._id },
    });
    console.log("job", job?._id.toString());
    const startWatching = async (changeStream) => {
      console.log("Watching for changes");
      try {
        for await (const change of changeStream) {
          console.log("Change detected", change);
          this.setState(change.fullDocument.status);
        }
      } catch (error) {
        if (error.name === "WatchError") {
          console.error("WatchError occurred, restarting change stream");
          changeStream = await jobs.watch({ filter: query });
          startWatching(changeStream);
        } else {
          console.error("Unexpected error occurred: ", error);
        }
      }
    };

    startWatching(changeStream);
  }

  connectedCallback() {
    // HTML template
    const template = `
      <div class="ai-store-builder">
        <div class="ai-container"></div>
      </div>
      
      <style>
        
        .ai-store-builder {
          position: fixed;
          bottom: 0;
          left: 50%;
          margin-bottom: -100vh;
          // max-height: 0;
          overflow: hidden;
          transform: translate(-50%, 0%);
          background-color: rgba(0, 11, 0, 0.9); // rgba(255, 255, 255, 0.92); 
          color: rgba(255, 255, 255, 0.9); // rgba(0, 0, 0, 0.92); //
          font-weight: bold;
          box-shadow: 0 0 10px rgba(33, 66, 33, 0.5);
          border: 1px solid rgba(0, 255, 0, 0.2);
          border-left-color: rgba(0, 255, 0, 0.1);
          border-right-color: rgba(0, 255, 0, 0.1);
          border-bottom: none;
          border-radius: 8px 8px 0 0 ;
          width: 96vw;
          max-width: 480px;
          z-index: 1000;
          text-align: left;
          font-size: 1.2em;
          line-height: 1.5;
          transition: all 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);
          backdrop-filter: blur(12px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
          .ai-container {
            margin: 24px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: stretch;
            max-width: 480px;
        }
          .ai-video {
            display: block;
          }
          .ai-video iframe {
            width: 100%;
            height: 33vh;
            max-height: 291px;
          }
        .ai-store-builder p {
          margin: 0 0 8px;
        }
          .ai-store-builder.ai-fullscreen .ai-container {
            width: 100%;
          }
          .ai-store-builder.ai-fullscreen {
            left: 0;
            right: 0;
            bottom: 0;
            height: 92vh !important;
            margin-bottom: 0;
            max-width: 100vw;
            width: 100vw !important;
            transform: translate(0%, 0%);
            background-color: rgba(0, 11, 0, 0.96);
          }
          .ai-store-builder.ai-loaded {
            margin-bottom: 0;
            height: auto;
          }
        form {
          display: flex;
          flex-direction: column;
          align-items: center stretch;
          margin-top: 16px;
          border-radius: 16px;
          gap: 16px;
        }
        
        .ai-store-builder input {
          flex: 1;
          padding: 16px;
          border: none;
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 0.08);
          border-radius: 16px;
          font-size: 0.8em;
          font-weight: inherit;
          font-family: inherit;
          color: #fff;
        }
        .ai-store-builder input:focus {
          outline: none;
          border: 2px solid rgba(0, 255, 0, 0.6);
        }
        button, .button {
          padding: 10px 20px;
          
          //background-color: rgba(0, 255, 0, 0.5);
          color: rgba(255, 255, 255, 0.8);
          border: none;
          border-radius: 16px;
          cursor: pointer
          font-weight: bold !important;
          font-family: inherit;
          font-size: 1em;
          transition: all 0.2s;
        }
        input:placeholder-shown {
          border: 2px solid rgba(0, 255, 0, 0.3);
        }
        button, .button.button-cta {
          display: block;
          text-align: center;
          margin-top: 0;
          text-decoration: none;
          border-top: 1px solid rgba(255, 255, 255, 0.32) !important;
          border-bottom: 8px solid rgba(255, 255, 255, 0.32) !important;
          background: linear-gradient(
            to bottom right,
            rgba(33, 255, 33, 0.8), 
            rgba(0, 133, 0, 0.8)  
          );
        }
        button:active, .button.button-cta:active {
          border-bottom-width: 1px !important;
          margin-top: 7px;
        }
          form:invalid button {
            background-color: rgba(0, 255, 0, 0.33);
            border-top-width: 0px !important;
            border-bottom-width: 2px !important;
          }
      </style>
    `;

    // Attach the template content to the shadow DOM
    this.shadowRoot.innerHTML = template;
    this.setState("default");
    setTimeout(() => {
      this.shadowRoot
        .querySelector(".ai-store-builder")
        .classList.add("ai-loaded");
    }, 666);
    this.getJob();
  }
}

// Define the new element
customElements.define("ai-store-builder", AiStoreBuilder);
