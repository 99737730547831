class AiLoader extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    const loaderStyle = document.createElement("style");
    loaderStyle.textContent = `
            :host {
                display: inline-block;
                width: 24px;
                height: 24px;
                border: 5px solid rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                border-top-color: #3498db;
                animation: spin 1s ease-in-out infinite;
            }

            @keyframes spin {
                to {
                    transform: rotate(360deg);
                }
            }
        `;
    this.shadowRoot.appendChild(loaderStyle);
  }
}

customElements.define("ai-loader", AiLoader);
