const createID = ({ pathname, behaviorId }) => {
  const parts = pathname.split("/");

  if (parts.length < 5) {
    parts.push(behaviorId);
  }
  console.log("parts", parts);

  const extractId = (type) => {
    let index;

    switch (type) {
      case "system":
        return "/";
      case "creator":
        index = 2;
        break;
      case "tool":
        index = 3;
        break;
      case "function":
        index = 4;
        break;
      case "thread":
        index = 5;
        break;
      case "version":
        index = 6;
        break;
    }

    if (parts.length < index) return undefined;

    const id = parts.slice(0, index).join("/");
    return id;
  };

  return {
    system_id: extractId("system"),
    creator_id: extractId("creator"),
    tool_id: extractId("tool"),
    function_id: extractId("function"),
    thread_id: extractId("thread"),
    version_id: extractId("version"),
  };
};

module.exports = { createID };
