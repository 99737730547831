require("../Loader");

class AIMessages extends HTMLElement {
  constructor() {
    super();
    this.tasks = {};
    this.tasksGroups = {};
    this.tasksList = {};
    this.App = window.AI()?.App;
  }

  connectedCallback() {
    this.render();
    this.initReader();
  }

  render() {
    this.innerHTML = `
      <style>
        .Message {
          /* Seus estilos aqui */
        }
      </style>
      <div id="ai-component">
        <div id="messages"></div>
        <ai-loader></ai-loader>
      </div>
    `;
  }

  async initReader() {
    console.log("initReader");

    const jobs = await this.App.DB("jobs");
    console.log("jobs", jobs);
    const job = await jobs.findOne(
      { user_id: this.App.currentUser.id },
      { sort: { created_at: -1 } }
    );

    console.log("job", job);
    if (!job) return;

    if (job?.status === "done") {
      return this.onJobDone(job);
    }

    this.watchCollection({
      collection: "jobs",
      query: { _id: job._id },
      options: { sort: { created_at: -1 } },
      onChange: (change) => {
        const job = change.fullDocument;
        if (job.status === "done") {
          this.onJobDone(job);
        }
      },
    });

    const collection = this.getAttribute("collection");
    if (collection) {
      this.readAndWatchTasks({ collection, job });
    }
  }

  async readAndWatchTasks({ collection, job }) {
    const thread_id = job._id.toString();
    if (!thread_id) return;

    const query = { thread_id };
    const options = { sort: { created_at: 1 } };
    console.log("Read", collection);
    const docs = await this.App.DB(collection);
    let tasks = await docs.find(query, options);
    console.log("tasks", tasks);

    tasks.forEach((task) => {
      this.tasksList[task._id] = task;
      this.renderTask(task);
    });

    this.watchCollection({
      collection,
      query: { "fullDocument.thread_id": thread_id },
      onChange: (change) => {
        if (["insert"].includes(change.operationType)) {
          const task = change.fullDocument;
          if (!this.tasksList[task._id]) {
            this.renderTask(task);
            this.tasksList[task._id] = task;
          }
        }
      },
    });
  }

  async watchCollection({ collection, query, options = {}, onChange }) {
    const docs = await this.App.DB(collection);
    let changeStream = await docs.watch({ filter: query });

    const startWatching = async (changeStream) => {
      try {
        for await (const change of changeStream) {
          onChange(change);
        }
      } catch (error) {
        if (error.name === "WatchError") {
          console.error("WatchError occurred, restarting change stream");
          changeStream = await docs.watch({ filter: query });
          startWatching(changeStream);
        } else {
          console.error("Unexpected error occurred: ", error);
        }
      }
    };

    startWatching(changeStream);
  }

  onJobDone(job) {
    console.log("Job is done:", job);
    const onShopifyAccountComplete = () => {
      localStorage.setItem("shopify_store_url", job.last.url);

      const CTA = document.querySelector("#CTAButton");

      const clickEvent = new Event("click", {
        bubbles: true,
        cancelable: true,
      });
      CTA.dispatchEvent(clickEvent);

      const buttons = document.querySelectorAll("button");
      let DoneButton;

      buttons.forEach((button) => {
        if (button.textContent.trim().includes("Done")) {
          DoneButton = button;
        }
      });

      if (DoneButton) DoneButton.dispatchEvent(clickEvent);
      document.querySelector("#ai-component").remove();
    };
    onShopifyAccountComplete();
  }

  renderTask(task) {
    const container = this.querySelector("#messages");
    const section = document.createElement("section");
    section.classList.add("Message");
    section.setAttribute("data-role", task.role);
    section.innerHTML = task?.message?.text;
    container.appendChild(section);
  }
}

customElements.define("ai-messages", AIMessages);
