const Click = (target) => {
  if (target?.dataset?.incepted) return;
  target.dataset.incepted = true;

  target.addEventListener("click", async (event) => {
    AI().User.track({
      name: "click",
      event,
    });

    if (target.tagName !== "A") return;

    return;
  });
};

module.exports = { Click };
