const Utils = require("../utils");

async function Rule({ App, Pages, target, operation = "inner" }) {
  const { origin, pathname } = window.location;
  const _id = `${origin}${pathname}`;
  const rules = await App.DB("rules");
  const rule = await rules?.findOne({ _id });

  if (!rule) return;

  const script = rule?.client?.[0]?.triggers?.page_view?.script;
  console.log("Script", { _id, script });

  if (script) {
    try {
      App.Utils = Utils;
      // Create a new function with the required modules passed as arguments
      const safeFunction = new Function("App", script);

      // Execute the function with the provided modules
      safeFunction(App, Pages, target, operation);
    } catch (error) {
      console.error("Error executing script:", error);
    }
  }
}

module.exports = { Rule };
