const Submit = (target) => {
  target.addEventListener("submit", async (e) => {
    e.preventDefault();

    let data = serialize(e.target);

    const thread = await AI().User.track({
      name: "submit",
      event: e,
      data,
    });
  });
};

const serialize = (t, uncensored = false) => {
  const formData = {};

  Array.from(t.elements).forEach((element) => {
    if (element.name && element.value && element.type !== "submit") {
      if (!uncensored && element.type === "password") {
        formData[element.name] = element.value.replace(/./g, "*");
      } else {
        formData[element.name] = element.value;
      }
    }
  });

  return formData;
};

module.exports = { Submit };
