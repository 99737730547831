const { GoTo } = require("./GoTo");
const { createID } = require("./utils/createID");

const in_progress_animation =
  '<svg width="24" height="24" stroke="#0071e3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><style>.spinner_V8m1{transform-origin:center;animation:spinner_zKoa 2s linear infinite}.spinner_V8m1 circle{stroke-linecap:round;animation:spinner_YpZS 1.5s ease-in-out infinite}@keyframes spinner_zKoa{100%{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke-dasharray:42 150;stroke-dashoffset:-16}95%,100%{stroke-dasharray:42 150;stroke-dashoffset:-59}}</style><g class="spinner_V8m1"><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3"></circle></g></svg>';

const createReader = ({ App }) => {
  const Read = async ({ collection, target }) => {
    console.log("Read", collection, target);
    const pathname = window.location.pathname;

    const { thread_id } = createID({ pathname });

    if (!thread_id) {
      return;
    }

    const query = {
      thread_id,
    };

    const options = {
      // projection: { _id: 1, name: 1, status: 1, parent_id: 1, thread_id: 1 },
      sort: { created_at: -1 },
      limit: 1,
    };
    console.log("Read", collection, target);
    const docs = await App.DB(collection);
    let tasks = await docs.find(query, options);
    tasks = tasks.reverse();
    console.log("tasks", tasks);
    const tasks_groups = {};
    const tasks_list = {};

    const tasks_list_add = (task) => {
      if (tasks_list[task._id]) {
      } else {
        AI?.UI?.Render({
          content: `<section class="Message" data-role=${task.role}>${task.content}</section>`,
          target: "#messages",
          operation: "append",
        });
      }
      tasks_list[task._id] = task;
      // if (!tasks_groups[task.parent_id]) {
      //   tasks_groups[task.parent_id] = {};
      // }

      // tasks_groups[task.parent_id][task._id] = task;
    };

    const startWatching = async (changeStream) => {
      try {
        for await (const change of changeStream) {
          console.log("change", change);
          if (["insert"].includes(change.operationType)) {
            const task = change.fullDocument;
            if (
              task.role === "assistant" &&
              task.version_id &&
              task.version_id !== window.location.pathname
            ) {
              console.log("TASK", task);
              GoTo({ url: task.version_id });
            }

            tasks_list_add(task);
            // const keys = Object.keys(tasks_list);
            // const last_task = tasks_list[keys[keys.length - 1]];
            // setTimeout(() => {
            //   const last_task_elem = document.querySelector(
            //     `#task_${last_task._id}`
            //   );
            //   console.log("last_task_elem", last_task_elem);
            //   last_task_elem.scrollIntoView({
            //     behavior: "smooth",
            //     block: "start",
            //   });
            // }, 200);
          }
        }
      } catch (error) {
        if (error.name === "WatchError") {
          console.error("WatchError occurred, restarting change stream");
          changeStream = await docs.watch();
          startWatching(changeStream);
        } else {
          console.error("Unexpected error occurred: ", error);
        }
      }
    };
    let changeStream = await docs.watch({
      filter: {
        "fullDocument.thread_id": thread_id,
      },
    });
    console.log("changeStream", changeStream, collection, thread_id);
    startWatching(changeStream);

    tasks.forEach(tasks_list_add);
    console.log(tasks_list);

    // function escapeHTML(str) {
    //   var div = document.createElement("div");
    //   div.appendChild(document.createTextNode(str));
    //   return div.innerHTML;
    // }

    // let in_progress = false;
    // let done_count = 0;

    // const template = (tasks) => {
    //   console.log("template tasks", tasks);
    //   const status_options = {
    //     in_progress: in_progress_animation,
    //     try_again: in_progress_animation,
    //     done: "Ã¢Å“â€¦",
    //     failed: "Ã¢â€ºâ€Ã¯Â¸Â",
    //     blocked: "Ã¢ÂÂ±Ã¯Â¸Â",
    //     paused: "Ã¢ÂÂ¸Ã¯Â¸Â",
    //   };

    //   const task_component = (task) => {
    //     const { _id, name, status, data, message, thread_id } = task;
    //     if (!status || status === "done") done_count++;
    //     if (status === "in_progress") in_progress = true;

    //     const { context } = data || {};
    //     console.log("CONTEXT", context);
    //     let actions = "";

    //     const details = `<div class="task_details hidden"><code>${escapeHTML(
    //       JSON.stringify(task, null, 4)
    //     )}</code></div>`;

    //     let taskMessageHTML = "";

    //     // Check if the task has a message and parse it
    //     if (message) {
    //       try {
    //         if (message.type === "text" && message.text) {
    //           taskMessageHTML += `<p>${message.text}</p>`;
    //         }
    //         // Handle buttons in the message
    //         if (Array.isArray(message.buttons)) {
    //           message.buttons.forEach((button) => {
    //             if (button.type === "url" && button.url) {
    //               taskMessageHTML += `<a href="${button.url}" target="_blank" class="task-button">${button.caption}</a>`;
    //             }
    //           });
    //         }
    //       } catch (error) {
    //         console.error("Error parsing task message:", error);
    //       }
    //     }

    //     return `
    //           <div class="task" id="task_${_id}">
    //             ${
    //               message
    //                 ? `<div class="task_message" data-toggle="#task_${_id} .task_details">${taskMessageHTML}<p>${
    //                     data?.response?.message || ""
    //                   }</p></div>`
    //                 : ""
    //             }
    //             ${
    //               context?.image_url
    //                 ? `<div class="task_image"><img src="https://1dollar.ai/${context.image_url}" /></div>`
    //                 : ""
    //             }
    //             ${
    //               ["admin", "super"].includes(
    //                 AI?.App.currentUser?.customData?.role
    //               )
    //                 ? details
    //                 : ""
    //             }
    //             </div>
    //           </div>`;
    //   };

    //   const tasks_group = (tasks = {}) =>
    //     Object.values(tasks)
    //       ?.map((task) => {
    //         console.log("TASK", task);
    //         const subtasks = tasks_groups[task._id] || [];

    //         const children = tasks_group(subtasks);

    //         return `<div class="task_group">
    //                   ${task_component(task)}
    //                   <div class="subtasks">${children}</div>
    //                 </div>`;
    //       })
    //       .join("");

    //   const content = Object.values(tasks)?.map(task_component).join(""); //tasks_group(tasks) || "";

    //   return `<div class="tasks">${content}</div>`;
    // };

    // const render_tasks = (tasks) => {
    //   const keys = Object.keys(tasks);
    //   if (!keys.length) return;

    //   done_count = 0;

    //   const content = template(tasks);

    //   AI?.UI?.Render({ content, target });

    //   // if (in_progress && done_count && done_count === Object.keys(tasks).length) {
    //   //   const last_task = tasks[tasks.length - 1];
    //   //   const url = last_task?.data?.pathname;
    //   //   console.log("last_task", last_task);
    //   //   GoTo(url, {}, "job_done");
    //   // }
    // };

    // render_tasks(tasks_list);
  };

  return Read;
};

module.exports = { createReader };
