const GoTo = async ({ url, event, event_name = "click", data }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirect_to = urlParams.get("redirect_to");
  url = redirect_to || url;

  if (!url) return;

  let output_url = url;

  //   if (event) {
  //     const event_response = await Event(event_name, event, data);

  //     if (event_name === "submit" && event_response?.insertedId) {
  //       _id = event_response?.insertedId?.toString();
  //       output_url += "?thread=" + _id;
  //     }
  //   }

  const isBlankTarget = event?.target?.target === "_blank";

  if (isBlankTarget) {
    window.open(output_url, "_blank").focus();
  } else {
    window.location.href = output_url;
  }
};

module.exports = { GoTo };
