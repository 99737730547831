async function Fetch({ url, method = "GET", body }) {
  try {
    const response = await fetch(url, {
      method,
      body,
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.text();
  } catch (error) {
    console.error(error);
  }
}

module.exports = { Fetch };
