const { Realm } = require("/app/lib/realm-web.js");
const { Page, Component, Render, Incept } = require("./lib/Incept/index.js");
const { Identity } = require("./lib/Identity.js");
const { GoTo } = require("./lib/GoTo.js");
const Utils = require("./lib/utils/index.js");

// require("./components/CreatorModeToggle.js");
// require("./components/ChatButton.js");
// require("./components/Assistant/Assistant.js");
require("./components/Messages/Messages.js");

const createDB = ({ App, collectionName }) => {
  return (collectionName) =>
    App?.currentUser
      ?.mongoClient("mongodb-atlas")
      ?.db("1dollar-ai-production")
      ?.collection(collectionName);
};

const init = async ({ appId }) => {
  // Start the Realm application
  const App = new Realm.App({ id: appId });
  const DB = createDB({ App });
  App.DB = DB;

  const User = await Identity({ App });
  // function makeAllElementsEditable() {
  //   // Select all elements on the page
  //   const allElements = document.querySelectorAll("body *");

  //   const elementsWithDirectText = Array.from(allElements).filter((element) => {
  //     return element.childElementCount === 1;
  //   });

  //   // Iterate over each element
  //   elementsWithDirectText.forEach((element) => {
  //     // Set the contenteditable attribute to true
  //     element.setAttribute("contenteditable", "true");
  //     element.setAttribute("spellcheck", "false");
  //     element.addEventListener("focus", function (event) {
  //       console.log("Focus:", event.target);
  //     });
  //     element.addEventListener("blur", function (event) {
  //       console.log("Blur", event.target);
  //     });
  //     element.addEventListener("keydown", function (event) {
  //       if (event.key === "Enter") {
  //         console.log("Enter Key Pressed:", event.target);
  //       }
  //     });

  //     // Add an event listener for double-click events
  //     element.addEventListener("dblclick", function (event) {
  //       // Log the element that was edited
  //       console.log("Double Click:", event.target);
  //     });
  //   });
  // }

  // // Call the function to make all elements editable
  // makeAllElementsEditable();

  // Função a ser executada quando a URL muda
  async function onUrlChange() {
    User.track({ name: "page_view", target: document.querySelector("body") });
    Page({ App });
  }

  // Override da função pushState para capturar mudanças de URL feitas por history.pushState
  (function (history) {
    const pushState = history.pushState;
    history.pushState = function (state) {
      if (typeof history.onpushstate == "function") {
        history.onpushstate({ state: state });
      }
      const result = pushState.apply(history, arguments);
      onUrlChange(); // Chame a função onUrlChange aqui
      return result;
    };
  })(window.history);

  // Override da função replaceState para capturar mudanças de URL feitas por history.replaceState
  (function (history) {
    const replaceState = history.replaceState;
    history.replaceState = function (state) {
      if (typeof history.onreplacestate == "function") {
        history.onreplacestate({ state: state });
      }
      const result = replaceState.apply(history, arguments);
      onUrlChange(); // Chame a função onUrlChange aqui
      return result;
    };
  })(window.history);

  // Ouve o evento popstate, acionado quando o histórico do navegador é modificado
  window.addEventListener("popstate", onUrlChange);

  onUrlChange();

  const UI = { Component, Render };

  const AI = {
    App,
    User,
    UI,
    GoTo,
    Incept,
    Utils,
  };

  if (window.location.pathname === "/") return AI;

  // Cria um novo elemento iframe
  var iframe = document.createElement("iframe");

  // Define os atributos de estilo para que o iframe ocupe toda a janela
  iframe.style.position = "fixed";
  iframe.style.top = "0";
  iframe.style.left = "0";
  iframe.style.width = "100%";
  iframe.style.height = "100%";
  iframe.style.border = "none";
  iframe.style.zIndex = "0"; // Para garantir que fique no topo

  // Define o src do iframe (URL que será carregada dentro do iframe)
  iframe.src = window.location.pathname.replace(/^\//, "");
  console.log("iframe.src", iframe.src);

  // Adiciona o iframe ao corpo do documento
  document.body.appendChild(iframe);

  //   window.AI = AI;
  console.log("AI initialized successfully.", window);
  return AI;
};

const { localStorage: local, location } = window;
const urlParams = new URLSearchParams(location.search);

let appId = urlParams.get("ai") || local.getItem("ai") || "1dollar-ai-oqcxp";
let App;

window.AI = function (input) {
  switch (input) {
    case "start":
      appId = "1dollar-ai-oqcxp";
      local.setItem("ai", appId);
      App = init({
        appId,
      }).then((AI) => {
        App = AI;
        require("./components/StoreBuilder/StoreBuilder.js");
      });
      break;
    case "stop":
      local.removeItem("ai");
      window.location.reload();
      break;
    default:
      return App;
  }
};

if (appId) {
  window.AI("start");
}
