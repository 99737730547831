const { Incept } = require("./Incept");

async function Render({ content, target, operation = "inner" }) {
  if (typeof target === "string") {
    target = document.querySelector(target);
  }

  if (target) {
    updateElementWithHTML(target, content, operation);
    target.classList.add("loaded");
  }
}

function updateElementWithHTML(target, content, operation = "inner") {
  switch (operation) {
    case "append":
      // Append the new HTML to the end of the element's content
      target.insertAdjacentHTML("beforeend", content);
      break;
    case "prepend":
      // Prepend the new HTML to the start of the element's content
      target.insertAdjacentHTML("afterbegin", content);
      break;
    case "after":
      // Add the new HTML after the element
      target.insertAdjacentHTML("afterend", content);
      break;
    case "before":
      // Add the new HTML before the element
      target.insertAdjacentHTML("beforebegin", content);
      break;
    default:
      // Set the innerHTML of the element
      target.innerHTML = content;
  }

  // Find and execute scripts in the newly added HTML
  var scripts = target.getElementsByTagName("script");

  for (var i = 0; i < scripts.length; i++) {
    // Create a new script element
    var script = document.createElement("script");

    // Copy attributes from the original script to the new script
    Array.from(scripts[i].attributes).forEach((attr) =>
      script.setAttribute(attr.name, attr.value)
    );

    // Set the text of the new script
    script.text = scripts[i].text;
    try {
      // Replace the original script with the new one to execute it
      scripts[i]?.parentNode?.replaceChild(script, scripts[i]);
    } catch (error) {
      console.error("scripts", error);
    }
  }

  Incept(target);
}

module.exports = { Render };
