const { getXPath } = require("./utils/XPath");

const createTracker = ({ App }) => {
  const Track = async ({ name, event, data, target }) => {
    console.log("Event", name, event, data);
    //   if (name === "error") {
    //     let errorElement = document.querySelector(".error");
    //     let errorHTML = `<p>${data.error}</p>`;
    //     let operation = errorElement ? "inner" : "append";

    //     if (!errorElement) {
    //       errorElement = document.querySelector("body > article > section");
    //       errorHTML = `<div class="error">${errorHTML}</div>`;
    //     }
    //     updateElementWithHTML(errorElement || event.target, errorHTML, operation);
    //     errorElement.classList.remove("hidden");
    //   }

    const collection = await App.DB("behaviors");
    console.log("Collection", collection);
    target = target || event?.target;
    const user = App.currentUser;

    const behavior = {
      user_id: user?.id,
      event: name,
      version: parseInt(document.querySelector("html").dataset.version) || 0,
      created_at: new Date(),
    };

    if (target?.attributes?.action) {
      behavior.action = target?.attributes?.action?.value;
    }

    const FormatAttrs = (data) => {
      if (typeof data !== "object") return data;

      const keys = Object.keys(data);
      return keys.reduce((dataset, key) => {
        dataset[key] = data[key];

        const typeKey = `${key}-type`;

        if (keys.includes(typeKey)) {
          const type = data[typeKey];
          switch (type) {
            case "int":
              dataset[key] = parseInt(data[key]);
              break;
          }
        }

        return dataset;
      }, {});
    };

    if (target?.attributes?.length) {
      const attributeNodeArray = [...target.attributes];
      const attrs = attributeNodeArray.reduce((attributes, attribute) => {
        attributes[attribute.name] = attribute.value;
        return attributes;
      }, {});

      behavior.content = target.innerText;
      behavior.element = target.tagName;
      behavior.attrs = FormatAttrs(attrs);
    }

    var location = document.location;

    if (target) {
      const agent = target?.dataset?.agent;

      if (agent) {
        behavior.agent = agent;
      }
    }

    behavior.xpath = getXPath(target);
    behavior.user_role = user?.customData?.role;
    behavior.data = data;
    behavior.href = location.href;
    behavior.referrer = document.referrer;
    behavior.user_agent = navigator.userAgent;
    behavior.language = navigator.language || navigator.userLanguage;
    behavior.hostname = location.hostname;
    behavior.origin = location.origin;
    behavior.pathname = location.pathname;
    behavior.search = location.search;
    behavior.search_params = location.search
      .replace(/^\?|&$/g, "")
      .split("&")
      .reduce(function (obj, param) {
        var paramSplitted = param.split("=");
        obj[paramSplitted[0]] = paramSplitted[1];
        return obj;
      }, {});

    window.dataLayer?.push(behavior);

    try {
      console.log("Inserting behavior", behavior);
      return await collection.insertOne(behavior);
    } catch (err) {
      console.error("Failed to update event:", err);
      return;
    }
  };

  return Track;
};

module.exports = { createTracker };
